import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/auth/callback": [31,[4]],
		"/auth/login": [32,[4]],
		"/auth/password-recovery": [33,[4]],
		"/auth/touchpanel": [34,[4]],
		"/(app)/booking": [6,[2]],
		"/(app)/buildingmanager": [9,[2]],
		"/(app)/building": [~7,[2]],
		"/(app)/building/[id]": [~8,[2]],
		"/(app)/catering": [10,[2]],
		"/(app)/catering/menus": [11,[2]],
		"/(app)/catering/products": [12,[2]],
		"/(app)/catering/statistics": [13,[2]],
		"/(app)/digital-twin": [14,[2]],
		"/(app)/home": [~15,[2]],
		"/(app)/home/[id]": [~16,[2]],
		"/(app)/monitoring": [17,[2]],
		"/(app)/my-controls": [~18,[2]],
		"/(app)/my-controls/area/[id]": [~19,[2]],
		"/(app)/my-controls/building/[id]": [~20,[2]],
		"/(app)/my-controls/floor/[id]": [~21,[2]],
		"/(app)/services": [22,[2]],
		"/(app)/settings": [~23,[2]],
		"/(app)/suppliers": [24,[2]],
		"/(app)/tickets": [25,[2]],
		"/(app)/usermanagement": [~26,[2,3]],
		"/(app)/usermanagement/groups": [27,[2,3]],
		"/(app)/usermanagement/roles": [28,[2,3]],
		"/(app)/usermanagement/users": [29,[2,3]],
		"/(app)/visitors": [30,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';